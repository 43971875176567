/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/layout'
import { Container, TextContainer } from '../utils'
import { Partner, Header, WhyBecomePartner, BecomeAPartner } from '../components/pages/partners'
import Meta from '../components/layout/meta'
import { reviewStructuredData } from '../components/layout/meta/schema-org'

const RelativeContainer = styled(Container)`
    position: relative;
`
// Definition of the colors that can be used
const ColoredBackground = styled.div`
    padding: 2rem 0;
    color: ${(props) =>
        props.purple ? props.theme.color.new.softWhite : props.green ? 'inherit' : props.red ? props.theme.color.new.softWhite : props.blue ? 'white' : 'inherit'};
    background: ${(props) =>
        props.purple
            ? props.theme.color.tellowPurple
            : props.gray100
            ? props.theme.color.tellowGray100
            : props.gray200
            ? props.theme.color.tellowGray200
            : props.gray300
            ? props.theme.color.tellowGray300
            : props.green
            ? props.theme.color.tellowGreen
            : props.red
            ? props.theme.color.tellowRed
            : props.blue
            ? props.theme.color.tellowBlue
            : 'white'};

    h2 {
        color: ${(props) => (props.forceWhiteHeader ? 'white' : 'inherit')};
    }
`

const ClientBlock = styled.div`
    position: relative;
`

const ExtendedTextContainer = styled(TextContainer)`
    text-align: center;

    h2 {
        font-size: 2rem;
        line-height: 2.5rem;
        color: ${({ theme }) => theme.color.tellowBlack};
        margin-top: 1rem;
        letter-spacing: -0.025em;
        font-weight: 900;
        margin-bottom: 4rem;
    }

    p {
        margin-bottom: 4rem;
        opacity: 0.8;
        font-size: 0.85rem;
        font-weight: 700;
    }
`

const PartnersContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0 -1rem;
`

const SectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
    margin: 4rem 0;

    h2 {
        padding-top: 2rem;
        text-align: center;
        color: ${({ theme }) => theme.color.tellowPurple};
    }

    @media screen and (min-width: 770px) {
        transition: all 1s ease;
        padding: 0;
    }

    @media screen and (max-width: 770px) {
        flex-direction: column;
    }

    @media screen and (min-width: 770px) and (max-width: 990px) {
        transition: all 1s ease;
        padding-bottom: 4rem;
    }
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem 0 0 0;
    margin: 4rem 0 0 0;

    @media screen and (min-width: 770px) {
        transition: all 1s ease;
        padding: 1rem 0 0 0;
    }

    @media screen and (max-width: 766px) {
        flex-direction: column;
        padding-bottom: 2rem;
    }

    @media screen and (min-width: 770px) and (max-width: 990px) {
        transition: all 1s ease;
    }
`

const PartnersPage = ({ data }) => {
    const { edges: partners } = data.allPrismicPartner

    return (
        <Layout>
            <Meta
                title="Partner worden - Tellow"
                description="Ontdek Tellow's werk met partners; lees hoe successen worden behaald."
                path="/partners"
                jsonLD={reviewStructuredData}
            />
            {/* First Component : Header - Main title and Image */}
            <RelativeContainer>
                <HeaderContainer>
                    <Header />
                </HeaderContainer>
            </RelativeContainer>

            {/* Second Component : PartnersBlock - Title of Partners Part and list of Logos */}
            {partners && partners.length >= 1 && Boolean(partners.filter((prtnr) => prtnr.node.data.name.text !== 'PLACEHOLDER').length) ? (
                <ClientBlock>
                    <ColoredBackground gray200>
                        <ExtendedTextContainer light style={{ position: 'relative', margin: '0 auto', padding: '2rem 0 4rem 0' }}>
                            <h2>Bekijk onze partners die je voorgingen</h2>
                            <PartnersContainer>
                                {partners &&
                                    partners
                                        .filter((prtnr) => prtnr.node.data.name.text !== 'PLACEHOLDER')
                                        .sort((a, b) => a.node.data.priority - b.node.data.priority)
                                        .map((details, index) => <Partner key={index} {...details} />)}
                            </PartnersContainer>
                        </ExtendedTextContainer>
                    </ColoredBackground>
                </ClientBlock>
            ) : null}

            {/* Third Component : WhyBecomePartnerBlock - Cards with little reviews (logos, text) and points */}
            <RelativeContainer>
                <SectionContainer>
                    <WhyBecomePartner />
                </SectionContainer>
            </RelativeContainer>

            {/* Fourth Component : BecomePartnerBlock - Card with title, CTA and Midas's Photo */}
            <ColoredBackground gray200>
                <RelativeContainer>
                    <SectionContainer>
                        <BecomeAPartner />
                    </SectionContainer>
                </RelativeContainer>
            </ColoredBackground>
        </Layout>
    )
}

// Graphql to retrieve data of Prismic
export const Q = graphql`
    query PartnersQuery {
        allPrismicPartner {
            edges {
                node {
                    id
                    uid
                    _previewable
                    data {
                        logo {
                            url
                        }
                        metadescription {
                            text
                        }
                        metatitle {
                            text
                        }
                        name {
                            text
                        }
                        visitable
                        priority
                    }
                }
            }
        }
    }
`

export default withPrismicPreview(PartnersPage)
